import cx from 'classnames';
import { type CSSProperties, Fragment, type ReactElement, type ReactNode } from 'react';

import { brandConfig } from '@hubcms/brand';
import { type TStoryblock } from '@hubcms/domain-storyblock';
import { type TeaserPropsOptions, type ArticleTeaserData } from '@hubcms/domain-teaser';
import { getListPageResultTeaserProps, ListPageResultTeaser } from '@hubcms/ui-teaser';

import { ListPageDivider } from '../ListPageDivider/ListPageDivider';

import { AsideBlock } from './AsideBlock';
import { getArticlePosition } from './getArticlePosition';
import { getCurrentPageIndex } from './getCurrentPageIndex';
import { getSideContentRowSpan } from './getSideContentRowSpan';
import styles from './infinite-article-list.module.scss';

interface ArticleListProps {
  index: number;
  arr: ArticleTeaserData[];
  article: ArticleTeaserData;
  initialPageSize: number;
  pageSize: number;
  hasMorePages: boolean;
  getAdFormatForPage: (index: number) => string | null;
  prependChildren?: ReactNode;
  getAdClassName?: (index: number) => string;
  teaserHasDateTime: boolean;
  asideRecirculationStoryblocks?: TStoryblock[];
  teaserPropsOptions: TeaserPropsOptions;
}

export function mapArticleToListPageResultTeaser({
  index,
  article,
  arr,
  initialPageSize,
  pageSize,
  hasMorePages,
  teaserHasDateTime,
  prependChildren,
  getAdFormatForPage,
  teaserPropsOptions,
  asideRecirculationStoryblocks = [],
  getAdClassName = () => '',
}: ArticleListProps): ReactElement {
  const isTagIntroB2B = article.contentType === 'tagIntroB2B';
  const currentPageIndex = getCurrentPageIndex(index, initialPageSize, pageSize);
  const teaserProps = getListPageResultTeaserProps(
    article,
    brandConfig.isAutomatedTeaserIntroShown,
    teaserHasDateTime,
    isTagIntroB2B,
  );
  const adFormat = getAdFormatForPage(currentPageIndex);

  const { isLastArticleOfList, isArticleWithInBetween } = getArticlePosition({
    index,
    initialPageSize,
    pageSize,
    articleListLength: arr.length,
    hasMorePages,
  });

  const asideStoryblocksForPage = isArticleWithInBetween
    ? asideRecirculationStoryblocks.slice(currentPageIndex, currentPageIndex + 1)
    : [];

  const asideStyles = {
    '--i-ad-row-lg': 1 + (currentPageIndex === 0 ? 0 : initialPageSize + pageSize * (currentPageIndex - 1)),
    '--i-sidecontent-grid-row-span': getSideContentRowSpan({
      initialPageSize,
      currentPageIndex,
      articleListSize: arr.length,
      pageSize,
      hasPrependChildren: !!prependChildren,
      getAdFormatForPage,
      asideRecirculationStoryblocks,
    }),
  } as CSSProperties;

  return (
    <Fragment key={article.id}>
      <div className={cx({ [styles.b2bTeaser]: isTagIntroB2B }, styles.mainContent)}>
        <ListPageResultTeaser key={article.id} {...teaserProps} />
        {!isLastArticleOfList && !isTagIntroB2B && <ListPageDivider />}
      </div>
      {isArticleWithInBetween && (
        <AsideBlock
          adClassName={getAdClassName(index)}
          adFormat={adFormat}
          storyblocks={asideStoryblocksForPage}
          style={asideStyles}
          teaserPropsOptions={teaserPropsOptions}
        />
      )}
    </Fragment>
  );
}
