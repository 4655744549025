import { Button } from '@mediahuis/chameleon-react';
import cx from 'classnames';
import type { ReactNode } from 'react';

import { brandConfig } from '@hubcms/brand';
import type { TStoryblock } from '@hubcms/domain-storyblock';
import { type TeaserPropsOptions, isArticleTeaserData, type TeaserData } from '@hubcms/domain-teaser';
import { useIsScreenSize } from '@hubcms/utils-browser';

import styles from './infinite-article-list.module.scss';
import { mapArticleToListPageResultTeaser } from './mapArticleToListPageResultTeaser';

interface InfiniteArticleListProps {
  articles: TeaserData[];
  initialPageSize: number;
  pageSize: number;
  isLoading: boolean;
  hasMorePages: boolean;
  getAdFormatForPage: (index: number) => string | null;
  loadMore: () => void;
  prependChildren?: ReactNode;
  className?: string;
  getAdClassName?: (index: number) => string;
  teaserHasDateTime: boolean;
  asideRecirculationStoryblocks?: TStoryblock[];
  teaserPropsOptions: TeaserPropsOptions;
}

export function InfiniteArticleList({
  articles,
  initialPageSize,
  pageSize,
  isLoading,
  hasMorePages,
  getAdFormatForPage,
  loadMore,
  prependChildren,
  className,
  teaserHasDateTime,
  teaserPropsOptions,
  asideRecirculationStoryblocks = [],
  getAdClassName = () => '',
}: InfiniteArticleListProps) {
  const isOnMobile = useIsScreenSize('sm');
  // Keep in mind the 2 extra articles displayed on desktop for the load more button logic.
  const alteredIntialPageSize = isOnMobile ? initialPageSize : initialPageSize + 2;

  return (
    <div className={cx(styles.content, className)}>
      {prependChildren}
      {articles.filter(isArticleTeaserData).map((article, index, arr) => {
        return mapArticleToListPageResultTeaser({
          index,
          article,
          arr,
          initialPageSize: alteredIntialPageSize,
          pageSize,
          hasMorePages,
          teaserHasDateTime,
          prependChildren,
          getAdFormatForPage,
          teaserPropsOptions,
          asideRecirculationStoryblocks,
          getAdClassName,
        });
      })}
      {hasMorePages && (
        <div className={cx(styles.mainContent)}>
          <Button loading={isLoading} appearance="secondary" className={cx(styles.loadMore)} onClick={loadMore}>
            {brandConfig.infiniteArticleListLoadMoreLabel}
          </Button>
        </div>
      )}
    </div>
  );
}
