import { Divider } from '@mediahuis/chameleon-react';
import cx from 'classnames';

import { brandConfig } from '@hubcms/brand';

import styles from './list-page-divider.module.scss';

export function ListPageDivider() {
  return <Divider className={cx(styles.listPageDivider, { [styles.showOnLg]: brandConfig.hasDividerInResultListLg })} />;
}
