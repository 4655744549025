import { brandConfig } from '@hubcms/brand';
import { useCook } from '@hubcms/data-access-cook';
import type { CustomizableGroupOptions, StoryblockAreas, TStoryblock } from '@hubcms/domain-storyblock';
import type { TeaserData, TeaserPropsOptions } from '@hubcms/domain-teaser';
import type { GGrid, TTeaserGrid } from '@hubcms/domain-teaser-grid';
import { useSublinkOptions } from '@hubcms/feature-my-section';
import { mapToCustomizableStoryblockGroup } from '@hubcms/utils-storyblock';

import type { GSectionAsideRecirculation } from './sectionGridExtensions';

export function useSectionPageGroup(
  title: string,
  asideRecirculationStoryblocks: TStoryblock[],
  teaserAreas: StoryblockAreas<TeaserData>,
  teaserPropsOptions: TeaserPropsOptions,
): TTeaserGrid {
  const data = useCook();
  const subLinkOptions = useSublinkOptions(data.sectionParams, title);

  const customizableGroupOptions: CustomizableGroupOptions = {
    type: 'CustomizableGroup',
    isHiddenOnMobile: false,
    areaOnePosition: brandConfig.sectionPageAreaOnePosition,
    title,
    areaOneOptions: {
      hasIntroDesktop: false,
      hasIntroMobile: false,
      isDefaultTeaser: brandConfig.sectionPageAreaOneTeasertype === 'default',
      isHighlighted: false,
    },
    areaTwoOptions: {
      hasImages: true,
      isAreaHiddenMobile: false,
      isHighlighted: false,
      isStackedMobile: brandConfig.sectionPageAreaTwoLayoutMobileApp === 'stacked',
    },
    areaThreeOptions: {
      hasImages: false,
      isAreaHiddenMobile: false,
      isHighlighted: false,
      isList: true,
      isListWithMobileSlider: false,
      isStackedMobile: false,
      listLayout: 'none',
      // A non-empty string is needed to ensure the group title doesn't span all column, but leaves place for the aside recirculation
      title: 'dummy',
    },
  };

  const mappedSectionTeaserAreas = {
    areaOne: [teaserAreas.groupArea[0]],
    areaTwo: teaserAreas.groupArea.slice(1, 3),
    areaThree: [],
  };

  const sectionPageStoryblock = mapToCustomizableStoryblockGroup(customizableGroupOptions, mappedSectionTeaserAreas);
  const asideRecirculationArea = sectionPageStoryblock.items.length - 1;
  sectionPageStoryblock.items[asideRecirculationArea] = convertAsideAreaToRecirculation(
    sectionPageStoryblock.items[asideRecirculationArea],
    asideRecirculationStoryblocks,
    teaserPropsOptions,
  );

  const sectionPageTitle = sectionPageStoryblock.title;

  if (sectionPageTitle) {
    sectionPageTitle.data.subLink = subLinkOptions;
    sectionPageTitle.data.headerLevel = 1;
  }

  return sectionPageStoryblock;
}

function convertAsideAreaToRecirculation(
  asideArea: GGrid['data']['items'][number],
  asideRecirculationStoryblocks: TStoryblock[],
  teaserPropsOptions: TeaserPropsOptions,
): GSectionAsideRecirculation {
  return {
    ...asideArea,
    type: 'sectionAsideRecirculation',
    data: {
      asideRecirculationStoryblocks,
      teaserPropsOptions,
    },
    gridProps: {
      ...asideArea.gridProps,
      hasInverseMarginInlineSm: true,
      hasInverseMarginInlineMd: true,
    },
  };
}
